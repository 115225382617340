declare global {
  // eslint-disable-next-line -- why var see in https://stackoverflow.com/questions/59459312/using-globalthis-in-typescript
  var grecaptcha: Grecaptcha
}

type Action =
  | '/auth/login/email/code/send'
  | '/password/recovery/code/send'
  | '/registration/code/send'
  | '/promocode/validate'
  | '/auth/login/email'

interface GrecaptchaAction {
  action: Action
}

interface Grecaptcha {
  enterprise: {
    ready: <T>(callback: () => T) => T
    execute: (key: string, action: GrecaptchaAction) => Promise<string>
  }
}

export function useRecaptcha() {
  const config = useRuntimeConfig()
  const apiKey = config.public.recaptchaKey

  const recaptchaToken = ref('')
  const isLoadingRecaptcha = ref(false)
  const recaptchaQueryParam = computed(() => ({
    recaptchaToken: recaptchaToken.value,
  }))

  async function getToken(action: Action) {
    try {
      isLoadingRecaptcha.value = true
      const tokenPromise = new Promise<string>((resolve) => {
        grecaptcha.enterprise.ready(async () => {
          const result = await grecaptcha.enterprise.execute(apiKey, {
            action,
          })
          resolve(result)
        })
      })

      recaptchaToken.value = await tokenPromise
    } catch (error) {
      console.error('loadRecaptchaToken error', error)
    } finally {
      isLoadingRecaptcha.value = false
    }
  }

  return {
    getToken,
    recaptchaToken,
    isLoadingRecaptcha,
    recaptchaQueryParam,
  }
}
